$(function () {
    jQuery.validator.addClassRules("field-required", {
        required: true,
        normalizer: function (value) {
            return $.trim(value);
        }
    });

    $('#isAccept').change(function () {
        var $this = $('#submit');
        if ($this.attr('disabled')) {
            $this.removeAttr('disabled');
        }
        else {
            $this.attr('disabled', 'disabled');
        }
    });

    $('.show-password-button').click(function () {
        var $iconButton = $(this);
        var $input = $('.password-input');
        if ($input.prop('type') == 'password') {
            $iconButton.attr('uk-icon', 'hide');
            $input.prop('type', 'text');
        }
        else {
            $iconButton.attr('uk-icon', 'show');
            $input.prop('type', 'password');
        }        
    });

    $('.remember-me').click(function () {
        var $checkbox = $(this);
        const current = $checkbox.val().toLowerCase();

        if (current == "true") {
            $checkbox.val("false");
        } else {
            $checkbox.val("true");
        }
    });
});

function redAlert() {
    let name = $("#userName").val();
    let password = $("#password").val();

    if (!password) {
        $("#password").addClass('border-danger');
    }

    if (!name) {
        $("#userName").addClass('border-danger');
    }
}

function isNameEmpty() {
    let name = $("#userName").val();

    if (name) {
        $("#userName").removeClass('border-danger');
    }
    else {
        $("#userName").addClass('border-danger');
    }
}

function isPasswordEmpty() {
    let password = $("#password").val();

    if (password) {
        $("#password").removeClass('border-danger');
    }
    else {
        $("#password").addClass('border-danger');
    }
}

$("#loginForm").submit(function () {
    var recaptcha = $("#g-recaptcha-response").val();
    if (recaptcha === "") {
        $('#warning').attr("hidden", false);
        return false;
    }  
});

function recaptchaCallback() {
    $('#warning').attr("hidden", true);
}

function togglePassword(inputId, linkElement) {
    var input = $("#" + inputId);
    if (input.attr("type") === "password") {
        input.attr("type", "text");
        $(linkElement).attr("uk-icon", "hide");;
    } else {
        input.attr("type", "password");
        $(linkElement).attr("uk-icon", "show");;
    }
}



function validateParams(formID) {
    var isValid = true;
    $("#" + formID + " input").each(function () {
        var val = $(this).val();
        var errorMessage = $(this).attr("data-val-required");
        var validationFor = $(this).attr("name");
        var validationSpan = $("span[data-valmsg-for='" + validationFor + "']");

        if (val == "") {
            validationSpan.text(errorMessage);
            isValid = false;
        } else {
            validationSpan.text(""); // Clear the error message if input is not empty
        }

        var recaptcha = $("#g-recaptcha-response").val();
        if (recaptcha === "") {
            $('#warning').attr("hidden", false);
            isValid = false;
        } 
    });

    return isValid;
}

$(".editable input").on("focus", function () {
    $(this).parents(".input-group").find("span").text("");
})

/*$("#restorePasswordForm button").on("click", function (event) {
    var isValid = validateParams("restorePasswordForm");

    if (!isValid) {
        event.preventDefault();
    }
})*/

//function buttonValidateAction(formId) {
//    $("button").on("click", function (event) {
//        var isValid = validateParams(formId);

//        if (!isValid) {
//            event.preventDefault();
//        }
//    })
//}

$("button.restoreButton").on("click", function (event) {
    var formId = $(this).parents('form').attr("id");
    var isValid = validateParams(formId);

    if (!isValid) {
        event.preventDefault();
    }
})